/* eslint-disable camelcase */
import { call,put, fork, takeEvery, all } from 'redux-saga/effects';
import axios from 'axios';
import { apiBaseUrl } from 'constants/defaultValues';
import {
  FETCH_VENDOR_LIST_REQUEST,
  fetchVendorListSuccess,
  fetchVendorListFailure,
} from '../actions';

const fetchVendorListAsync = async (requestBody) => {
  try {
    const response = await axios.post(`${apiBaseUrl}vendor/list`, requestBody);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

function* fetchVendorList({ payload }) {
  try {
    console.log("action",payload)
    const { category, type, is_archived, search_term, page, page_size } = payload.requestData;
    const requestBody = {
      category,
      type,
      is_archived,
      search_term,
      page,
      page_size,
    };

    // Make the API call
    const response = yield call(fetchVendorListAsync, requestBody);
    
    if (response.status === 1) {
      yield put(fetchVendorListSuccess(response.data.vendor_list));
    } else {
      yield put(fetchVendorListFailure(response.message));
    }
  } catch (error) {
    yield put(fetchVendorListFailure(error));
  }
}

function* watchFetchVendors() {
  yield takeEvery(FETCH_VENDOR_LIST_REQUEST, fetchVendorList);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchVendors)
  ]);
}