// eslint-disable-next-line import/no-cycle
import {
  FETCH_VENDOR_LIST_REQUEST,
  FETCH_VENDOR_LIST_SUCCESS,
  FETCH_VENDOR_LIST_FAILURE
} from '../actions';

export const fetchVendorListRequest = (requestData, history) => ({
  type: FETCH_VENDOR_LIST_REQUEST,
  payload: { requestData, history },
});

export const fetchVendorListSuccess = (vendors) => ({
  type: FETCH_VENDOR_LIST_SUCCESS,
  payload: vendors,
});

export const fetchVendorListFailure = (error) => ({
  type: FETCH_VENDOR_LIST_FAILURE,
  payload: error,
});